import User from '../User';

/**
 * ViewState
 * The viewstate is used for caching the filters and settings for for instance a table. When returning, the filters would remain the same.
 * This allows you to save the setup to the localStorage or sessionStorage.
 */
export default class ViewState {
    static data = [];

    /**
     * Get value from viewstate
     * @param {string} key  The primary key, e.g. 'campaigns'
     * @param {string} subkey  A subkey (optional), e.g. filters
     * @param {('local' | 'session')} typeOfStorage Decides whether the value is saved in localStorage or sessionStorage
     */
    static get(key, subkey = '', typeOfStorage = 'local') {
        let viewState;

        if (typeOfStorage === 'local') {
            viewState = localStorage.getItem(key + '_' + subkey + '_' + User.get('id'));
        } else if (typeOfStorage === 'session') {
            viewState = sessionStorage.getItem(key + '_' + subkey + '_' + User.get('id'));
        }

        if (viewState) {
            try {
                viewState = JSON.parse(viewState);
            } catch (e) {
                return undefined;
            }
        }

        if (viewState === null) {
            return undefined;
        }

        return viewState;
    }

    /**
     * Set the value for the viewstate.
     * @param {string} key The primary key, e.g. 'campaigns'
     * @param {string} subkey A subkey (optional), e.g. filters
     * @param {*} value The value as an object
     * @param {('local' | 'session')} typeOfStorage Decides whether the value is saved in localStorage or sessionStorage
     */
    static set(key, subkey = '', value, typeOfStorage = 'local') {
        if (typeOfStorage === 'local') {
            localStorage.setItem(key + '_' + subkey + '_' + User.get('id'), JSON.stringify(value));
        } else if (typeOfStorage === 'session') {
            sessionStorage.setItem(key + '_' + subkey + '_' + User.get('id'), JSON.stringify(value));
        }
    }

    /**
     * Remove the value for the viewstate.
     * @param {string} key The primary key, e.g. 'campaigns'
     * @param {string} subkey A subkey (optional), e.g. filters
     */
    static remove(key, subkey = '', typeOfStorage = 'local') {
        if (typeOfStorage === 'local') {
            localStorage.removeItem(key + '_' + subkey + '_' + User.get('id'));
        } else if (typeOfStorage === 'session') {
            sessionStorage.removeItem(key + '_' + subkey + '_' + User.get('id'));
        }
    }
}
