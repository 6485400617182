import moment from 'moment';
import validUrl from 'valid-url';
import { cloneDeep } from 'lodash';
import SimpleReactValidator from 'simple-react-validator';
import EditorData from 'components/editor-data/EditorData';
import socialChecks from './socialChecks';
import store from '../../../store';

/**
 * Validator class
 * Used to validate different conditions
 */
export default class Validator {
    /**
     * validateSet
     * Validate based on a set that is predefined in the campaign format.
     * @param {*} validateSet
     */
    static validateBySet(validateSet, additionalValidators = []) {
        const validatorSets = store.getState().editor.validatorSets;
        let set = [];
        if (validatorSets[validateSet]) {
            set = validatorSets[validateSet];
        }
        set = [...additionalValidators, ...set];

        return Validator.validate(set);
    }

    /**
     * Validate
     * This validates a list of options
     * @param {*} validators
     * @param {*} baseData
     * @return {array} Returns an array with all the failed checks and the corresponding messages
     */
    static validate(validators, baseData = undefined) {
        let results = [];
        validators.forEach((validator) => {
            // Condition validate
            if (validator.condition) {
                let blockData;
                if (validator.blockDataModel) {
                    blockData = EditorData.getValueFromModel(validator.blockDataModel);
                }
                if (!EditorData.validateCondition({ condition: validator.condition }, blockData)) {
                    results.push(validator);
                }
            }
            // Flights
            else if (validator.function === 'flights') {
                const resultsOfFunction = Validator.flightsValidator(validator);
                if (resultsOfFunction && resultsOfFunction.length > 0) {
                    results = [...results, ...resultsOfFunction];
                }
            }
            // Creative builder with standardized social checks
            else if (validator.function === 'creativeBuilderSocial') {
                const resultsOfFunction = Validator.creativeBuilderValidator(validator, socialChecks);
                if (resultsOfFunction && resultsOfFunction.length > 0) {
                    results = [...results, ...resultsOfFunction];
                }
            }
            // Creative builder
            else if (validator.function === 'creativeBuilder') {
                const resultsOfFunction = Validator.creativeBuilderValidator(validator, validator.validators);
                if (resultsOfFunction && resultsOfFunction.length > 0) {
                    results = [...results, ...resultsOfFunction];
                }
            }
            // Check for validity
            else if (validator.type) {
                const value = EditorData.getValueFromModel(validator.model);
                if (!this.validateValue(value, validator)) {
                    results.push(validator);
                }
            }
        });
        return results;
    }

    /**
     * Check validator for an individual value.
     * @param {*} value The value to check
     * @param {*} validator
     */
    static validateValue(value, validator) {
        try {
            // Empty value
            if (validator.type === 'required') {
                if (!value || value.length === 0 || value === '') {
                    return false;
                }
            }
            // Will pass validator if there is no value
            else if (validator.type === 'absent') {
                if (value !== undefined && value !== '') {
                    return false;
                }
            }
            // Multilanguage validation
            else if (validator.type === 'requiredMultiLanguage') {
                const languages = EditorData.getValueFromModel('settings.languages');
                for (const language in languages) {
                    let valueByLanguage = '';
                    if (value && value[language] && value[language].value) {
                        valueByLanguage = value[language].value;
                    }

                    if (!valueByLanguage || valueByLanguage.length === 0 || valueByLanguage === '') {
                        return false;
                    }
                }
            }

            // URL validation
            else if (validator.type === 'url') {
                if (value && (!validUrl.isUri(value) || value.substring(0, 8) !== 'https://' || value.indexOf(' ') >= 0)) {
                    return false;
                }
            }

            // Length validation
            else if (validator.type === 'length') {
                if (!validator.min) {
                    validator.min = 0;
                }
                if (!validator.max) {
                    validator.max = 20000000000;
                }

                if (value && (value.length < validator.min || value.length > validator.max)) {
                    return false;
                }
            }

            // Max number validation
            else if (validator.type === 'maxNumber') {
                const valueInt = parseInt(value);
                if (valueInt && valueInt > validator.max) {
                    return false;
                }
            }

            // Array length validation
            else if (validator.type === 'arrayLength') {
                if (!value.length) {
                    return false;
                }
                if (validator.min) {
                    if (value.length < validator.min) {
                        return false;
                    }
                }
                if (validator.max) {
                    if (value.length > validator.max) {
                        return false;
                    }
                }
            }

            // Date validation
            else if (validator.type === 'date') {
                if (!value) {
                    return false;
                }

                const date = moment(value);

                // Check if after today
                if (validator.subType === 'afterToday') {
                    if (date.isBefore()) {
                        return false;
                    }
                }
                // Check if after today
                if (validator.subType === 'beforeToday') {
                    if (date.isAfter()) {
                        return false;
                    }
                }
                // Just check for valid date
                else {
                    if (!date.isValid()) {
                        return false;
                    }
                }
            }
            // Use the standard validation functions
            else {
                const simpleValidator = new SimpleReactValidator();
                if (!simpleValidator.check(value, validator.type)) {
                    return false;
                }
            }
        } catch (e) {
            return false;
        }

        return true;
    }

    /**
     * Validator for flights
     * This loops through the flights and places the validator in front
     */
    static flightsValidator = (validator) => {
        const flights = EditorData.getValueFromModel('settings.flights');
        let results = [];

        // Loop trough all flights
        flights.forEach((flightItem) => {
            const validatorsOfItem = cloneDeep(validator.validators);

            // Clone the object
            validatorsOfItem.forEach((validatorItem, i) => {
                validatorsOfItem[i].message = 'Flight ' + flightItem.title + ': ' + validatorItem.message;
                validatorsOfItem[i].model = 'flights.' + flightItem.id + '.' + validatorItem.model;
                if (validatorsOfItem[i].dataModel) {
                    validatorsOfItem[i].dataModel = 'flights.' + flightItem.id + '.' + validatorItem.dataModel;
                }
                validatorsOfItem[i].blockDataModel = 'flights.' + flightItem.id;
            });
            const functionResults = Validator.validate(validatorsOfItem);
            if (functionResults) {
                results = [...results, ...functionResults];
            }
        });

        return results;
    };

    /**
     * Validator for the creative builder
     * This loops through all items and builds the
     */
    static creativeBuilderValidator = (validator, checks) => {
        const items = EditorData.getValueFromModel(validator.model);
        const data = EditorData.getValueFromModel(validator.dataModel);
        const validationResults = [];
        const languages = EditorData.getValueFromModel('settings.languages');

        try {
            items &&
                items.forEach((item) => {
                    // Get item data
                    const itemData = EditorData.getValueFromModel(item.uuid, data);

                    // Loop through all the validators
                    checks.forEach((check) => {
                        // Check whether we are targeting this type
                        if (Validator.creativeBuilderValidatorType(check, itemData)) {
                            let valueToCheck;

                            // Check for the top level
                            if (check.dataType === 'item') {
                                valueToCheck = EditorData.getValueFromModel(check.model, itemData);
                                if (!Validator.validateValue(valueToCheck, check)) {
                                    let message = itemData.title;
                                    message = message ? message + ': ' + check.message : check.message;
                                    validationResults.push({ message: message });
                                }
                            } else {
                                // Loop through all subsets
                                itemData.subsets.forEach((subsetItem, subsetIndex) => {
                                    const subsetNr = subsetIndex + 1;
                                    const subsetData = itemData.data[subsetItem];

                                    if (check.dataType === 'subset') {
                                        valueToCheck = EditorData.getValueFromModel(check.model, subsetData);
                                        if (!Validator.validateValue(valueToCheck, check)) {
                                            let message = itemData.title;
                                            if (subsetNr > 1) {
                                                message = message + ' - subset ' + subsetNr;
                                            }
                                            message = message + ': ' + check.message;
                                            validationResults.push({ message: message });
                                        }
                                    }

                                    // Add frames
                                    let frames = [''];
                                    if (check.multiFrame) {
                                        frames = [];
                                        for (let i = 1; i <= itemData.frames; i++) {
                                            frames.push('frame' + i);
                                        }
                                    }

                                    // Loop through all frames
                                    frames.forEach((frame) => {
                                        let valueToCheck;

                                        // In case we want to check data of the asset, set it here.
                                        if (check.dataType === 'asset') {
                                            valueToCheck = EditorData.getValueFromModel(
                                                'assetData.' + (frame ? 'frames.' + frame + '.' : '') + check.model,
                                                subsetData
                                            );
                                        } else {
                                            valueToCheck = EditorData.getValueFromModel(
                                                'channelData.' + (frame ? 'frames.' + frame + '.' : '') + check.model,
                                                subsetData
                                            );
                                        }

                                        // In case of multilanguage, check for all languages
                                        if (check.multiLanguage) {
                                            for (const language in languages) {
                                                const valueToCheckLanguage = EditorData.getValueFromModel(language + '.value', valueToCheck);

                                                if (!Validator.validateValue(valueToCheckLanguage, check)) {
                                                    let message = itemData.title + ' - ' + language;
                                                    if (frame) {
                                                        message = message + ' - ' + frame;
                                                    }
                                                    if (subsetNr > 1) {
                                                        message = message + ' - variant ' + subsetNr;
                                                    }
                                                    message = message + ': ' + check.message;
                                                    validationResults.push({ message: message });
                                                }
                                            }
                                        }
                                        // If not, just check the main object.
                                        else {
                                            if (!Validator.validateValue(valueToCheck, check)) {
                                                let message = itemData.title;
                                                if (frame) {
                                                    message = message + ' - ' + frame;
                                                }
                                                if (subsetNr > 1) {
                                                    message = message + ' - subset ' + subsetNr;
                                                }
                                                message = message + ': ' + check.message;
                                                validationResults.push({ message: message });
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    });
                });
        } catch (e) {
            console.log('Error validating', e);
        }

        return validationResults;
    };

    /**
     * Check Validator type
     * @param {*} check
     * @param {*} itemData
     */
    static creativeBuilderValidatorType(check, itemData) {
        if (check.itemType && itemData.type !== check.itemType) {
            return false;
        }
        if (
            check.channelTemplateIdentifier &&
            Array.isArray(check.channelTemplateIdentifier) &&
            !check.channelTemplateIdentifier.includes(itemData.channelSetup.templateIdentifier)
        ) {
            return false;
        }

        if (
            check.channelTemplateIdentifier &&
            !Array.isArray(check.channelTemplateIdentifier) &&
            check.channelTemplateIdentifier !== itemData.channelSetup.templateIdentifier
        ) {
            return false;
        }

        return true;
    }
}
