import EditorData from '../../editor-data/EditorData';

export default class VisualStackEditorHelpers {
    /**
     * Get the editingItem object for a uuid
     * @param {string} uuid unique block identifier
     * @param {array} value in the setup
     * @param {object} templates available templates in the editor
     * @returns editingItem object
     */
    static getEditingItem = (uuid, value, templates) => {
        if (!uuid) return null;

        const identifier = value.find((x) => x.uuid === uuid).identifier;
        if (!identifier) return null;

        const template = templates[identifier];
        if (!template) return null;

        return {
            identifier,
            title: template.title,
            uuid: uuid,
            interfaceSetup: template.interfaceSetup
        };
    };

    /**
     * Determine whether a block should be hidden in AB testing preview.
     * @param {array} abTestGroups active test groups for a specific block
     */
    static getHidden = (abTestGroups, abTesting, abTestGroupsShown) => {
        // do not hide if A/B testing isn't specified for this block
        if (!abTestGroups) return false;

        // Nothing is hidden if there is no AB testing
        if (!abTesting) return false;

        // Noting is hidden if the testgroups aren't set
        if (!abTestGroupsShown) return false;

        // Calculate hidden
        if (typeof abTestGroups === 'string') {
            return !abTestGroupsShown.some((r) => abTestGroups.includes(r));
        } else {
            return false;
        }
    };

    /**
     * Get the testgroups for a certain block.
     * @param {string} uuid
     * @param {object} sourceData
     */
    static getAbTestgroups = (uuid, sourceData) => {
        let abTestGroups = false;
        try {
            abTestGroups = sourceData && sourceData[uuid] && sourceData[uuid].abTestGroups ? sourceData[uuid].abTestGroups : false;
            return abTestGroups;
        } catch (e) {
            return false;
        }
    };

    /**
     * Retrieve the AB test setting from the campaign setup in Redux.
     * @param {string} settingsModel
     * @returns {array}
     */
    static getAbTestSetting = (settingsModel) => {
        const abTestSetting = EditorData.getValueFromModel(`${settingsModel}.abTesting.test`);
        if (!abTestSetting || abTestSetting === 'none') return false;
        return [...abTestSetting];
    };

    /**
     * Determine wheter the active or inactive color should apply for a chip.
     * @param {string} abTestGroups Current active test groups
     * @param {string} test Test to determine the color of
     * @returns 'primary' or 'default'
     */
    static getAbColor = (abTestGroups, test) => {
        let abTestGroupsArray = [];
        if (typeof abTestGroups === 'string' && abTestGroups.length > 0) {
            abTestGroupsArray = abTestGroups.split(',');
        }
        if (abTestGroupsArray.includes(test)) return 'primary';
        return 'default';
    };

    /**
     * Construct the text to show in the tooltip.
     * @param {*} abTestGroups
     * @param {string} test
     * @returns
     */
    static getAbTooltip = (abTestGroups, test) => {
        if (typeof abTestGroups === 'string') {
            const abTestGroupsArray = abTestGroups.split(',');
            if (abTestGroupsArray.includes(test)) return `Disable block for Test ${test.toUpperCase()}`;
        }
        return `Enable block for Test ${test.toUpperCase()}`;
    };

    /**
     * Store the A/B testing groups of a block in the Redux data model.
     * @param {string} uuid Identifier of the block
     * @param {array} value A/B testing value
     * @param {string} dataModel of the current editor
     */
    static setAbTesting = (uuid, value, dataModel) => {
        const valueString = Array.isArray(value) ? value.join() : value;
        EditorData.setModel(`${dataModel}.${uuid}.abTestGroups`, valueString);
    };

    /**
     * handle AB testing selection.
     * @param {*} abTestGroups current selected test groups
     * @param {string} test clicked value
     * @param {string} uuid identifier of the block
     * @param {string} dataModel of the current editor
     */
    static handleAbTesting = (abTestGroups, test, uuid, dataModel) => {
        let abTestGroupsArray = [];
        if (typeof abTestGroups === 'string' && abTestGroups.length > 0) {
            abTestGroupsArray = abTestGroups.split(',');
        }
        if (abTestGroupsArray.includes(test)) {
            abTestGroupsArray.splice(abTestGroupsArray.indexOf(test), 1);
        } else {
            abTestGroupsArray.push(test);
        }
        this.setAbTesting(uuid, abTestGroupsArray, dataModel);
    };

    /**
     * Run by al blocks to see if their AB testing settings complies with the general AB testing settings. If not, reset them.
     * @param {array} value
     * @param {string} abTesting
     * @param {string} dataModel
     */
    static checkAbTesting = (value, abTesting, dataModel) => {
        // Check AB testing on load
        if (abTesting) {
            value.forEach((v) => {
                const content = EditorData.getValueFromModel(`${dataModel}.${v.uuid}`);

                if (!content) {
                    // Add all testgroups if block is unknown.
                    this.setAbTesting(v.uuid, abTesting, dataModel);
                } else if (!content.abTestGroups || typeof content.abTestGroups !== 'string') {
                    // Add all testgroups if not set.
                    this.setAbTesting(v.uuid, abTesting, dataModel);
                } else if (content.abTestGroups.split(',').length > abTesting.length) {
                    // Reset AB testing in block if it has more groups then set.
                    this.setAbTesting(v.uuid, abTesting, dataModel);
                }
            });
        }
    };

    /**
     * Remove blacks that can't be tied to a template from the setup.
     * @param {array} value
     * @param {object} templates
     * @returns {array} value with valid blocks
     */
    static cleanUpValue = (value, templates) => {
        if (!Array.isArray(value)) return [];

        const cleanValue = value.filter((v) => {
            if (!v.identifier) return false;
            return templates[v.identifier];
        });

        return cleanValue;
    };
}
