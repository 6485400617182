import React from 'react';
import { connect } from 'react-redux';
import '../styles/main.scss';
import Icon from 'components/ui-components-v2/Icon';
import MultiInput from 'components/editor-blocks/MultiInput';
import { DataSetManagerInCampaign } from 'components/editor-blocks/DataSetManager';
import CampaignStatus from 'components/editor-blocks/CampaignStatus';
import TeamSelector from 'components/social/TeamSelector';
import SocialPreviewFacebook from 'components/channels/social/PreviewFacebook';
import SocialPreviewInstagram from 'components/channels/social/PreviewInstagram';
import SocialPreviewLinkedIn from 'components/channels/social/PreviewLinkedIn';
import SocialPreviewSnapchat from 'components/channels/social/PreviewSnapchat';
import SocialPreviewTwitter from 'components/channels/social/PreviewTwitter';
import SocialPreviewPinterest from 'components/channels/social/PreviewPinterest';
import SocialPreviewGoogle from 'components/creative-preview/SocialPreviewGoogle';
import SocialPreviewYoutube from 'components/channels/social/PreviewYoutube';
import SocialPreviewTikTok from 'components/channels/social/PreviewTikTok';
import SocialPreviewWeChat from 'components/channels/social/PreviewWeChat';
import StillAsset from 'components/assets/StillAsset';
import Comments from 'components/social/Comments';
import AffectivityPreview from 'components/channels/video/AffectivityPreview';
import PreviewMobileWrapper from 'components/creative-preview/PreviewMobileWrapper';
import DisplayPreview from 'components/channels/display/DisplayPreview';
import EmailPreview from 'components/channels/email/EmailPreview';
import LandingpagePreview from 'components/channels/landingpage/LandingpagePreview';
import CampaignFlightManager from 'components/campaigns/CampaignFlightManager';
import CampaignFlightCalendar from 'components/campaigns/CampaignFlightCalendar';
import CreativeBuilderPreview from 'components/creative-builder/CreativeBuilderPreview';
import SocialCustomizer from 'components/editor-blocks/SocialCustomizer';
import EditorData from 'components/editor-data/EditorData';
import Subsets from 'components/editor-base/EditorSubsets';
import CommentReviewHeader from 'components/social/CommentReviewHeader';
import InlineAlert from 'components/ui-components/InlineAlert';
// #if process.env.customer === 'klm' || process.env.customer === 'afkl' || process.env.customer === 'airfrance'
// import KLMFares from 'components/customers/KLM/KLMFares'; // eslint-disable-line
// import KLMEmailAIGenerator from 'components/customers/KLM/KLMEmailAIGenerator';
// import AFiFrame from 'components/customers/KLM/AFiFrame';
// #endif

// #if process.env.customer == 'philips'
// import PhilipsTrafficking from 'components/customers/Philips/PhilipsTrafficking'; // eslint-disable-line
// #endif

/**
 * Class EditorBlock
 * This component is often used by the editor.
 * The interface is build up in blocks that show the different options.
 * The blocks can be expanded to show all options. There are many different block contents available, like socialpreviews or customer specific blocks.
 * However, the standard block is the MultiInput block.
 */
class EditorBlock extends React.Component {
    /**
     * Constructor
     * Set up this component
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            opened: props.data.opened ? true : false
        };

        // Subsets available, select the correct one or generate a new one
        if (props.data.subsets) {
            // Get the blockModelBase data
            const blockModelData = EditorData.getDynamicValue('[[[campaign]]].' + props.data.blockModelBase, {});

            // There is a seperate subset model. This holds the active subset
            if (props.data.subsets.model) {
                this.state.subsetSelected = EditorData.getValueFromModel(props.data.subsets.model);
            }
            // We have no subsets defined, but consider all the keys in the object to be a subset
            else if (blockModelData) {
                this.state.subsetSelected = Object.keys(blockModelData)[0];
            }
        }

        if (!this.state.subsetSelected) {
            this.state.subsetSelected = 'main';

            if (props.data && props.data.subsets && props.data.subsets.model) {
                EditorData.setModel(props.data.subsets.model, 'main');
            }
        }
    }

    static mapStateToProps(state) {
        return {
            editor: state.editor
        };
    }

    /**
     * Toggle open and close in the block
     */
    onToggle = () => {
        if (!this.state.commentReviewIsOpen) {
            this.setState({ opened: this.state.opened ? false : true });
        }
    };

    /**
     * Set the active subset
     * @param {string} subset The key of the subset that is activated
     */
    handleChangeSubset = (subset) => {
        const { data } = this.props;

        // In case there is a model for the subsets, change the active value
        const model = data.subsets.model;
        if (model) {
            EditorData.setModel(model, subset);
        }

        this.setState({
            subsetSelected: subset
        });
    };

    /**
     * Sets whether the comment view is open in the block
     */
    commentReviewOpen = (open) => {
        this.setState({
            commentReviewIsOpen: open,
            opened: true
        });
    };

    /**
     * Render the block content
     * Based on the block type
     * @param {*} data Tge data
     * @param {*} blockModelInput The block model to be used. In case we have a
     */
    renderBlockContent = (data, blockModelInput = false, subsetSelected) => {
        let blockModel = blockModelInput;
        if (blockModel) {
            blockModel = blockModel.replace('[[subset]]', subsetSelected);
        }

        // Check conditional fields to see whether we can show this element.
        if (!EditorData.validateCondition(data)) {
            return <React.Fragment></React.Fragment>;
        }
        // Team block
        else if (data.type === 'team') {
            return <TeamSelector entityId={'campaign_team_' + this.props.editor.id} />;
        }
        // Review or commenting
        else if (data.type === 'review' || data.type === 'comment') {
            return (
                <Comments
                    blockModel={blockModel}
                    data={data}
                    entity={data.entity ? data.entity : 'general'}
                    campaignId={this.props.editor.id}
                    reviewType={data.reviewType}
                    canReview={data.canReview}
                />
            );
        }
        // Facebook preview
        else if (data.type === 'previewFacebook') {
            return (
                <PreviewMobileWrapper preview="facebook">
                    <SocialPreviewFacebook blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Instagram preview
        else if (data.type === 'previewInstagram') {
            return (
                <PreviewMobileWrapper preview="instagram">
                    <SocialPreviewInstagram blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Linkedin Preview
        else if (data.type === 'previewLinkedIn') {
            return (
                <PreviewMobileWrapper preview="linkedin">
                    <SocialPreviewLinkedIn blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Snapchat preview
        else if (data.type === 'previewSnapchat') {
            return (
                <PreviewMobileWrapper preview="snapchat">
                    <SocialPreviewSnapchat blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Google Preview
        else if (data.type === 'previewGoogle') {
            return (
                <PreviewMobileWrapper preview="google">
                    <SocialPreviewGoogle blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Youtube preview
        else if (data.type === 'previewYoutube') {
            return (
                <PreviewMobileWrapper preview="youtube">
                    <SocialPreviewYoutube blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Tiktok previews
        else if (data.type === 'previewTikTok') {
            return (
                <PreviewMobileWrapper preview="tiktok">
                    <SocialPreviewTikTok blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // WeChat previews
        else if (data.type === 'previewWeChat') {
            return (
                <PreviewMobileWrapper preview="wechat">
                    <SocialPreviewWeChat blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Twitter preview
        else if (data.type === 'previewTwitter') {
            return (
                <PreviewMobileWrapper preview="twitter">
                    <SocialPreviewTwitter blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Pinterest preview
        else if (data.type === 'previewPinterest') {
            return (
                <PreviewMobileWrapper preview="pinterest">
                    <SocialPreviewPinterest blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Affectivity preview
        else if (data.type === 'previewAffectivity') {
            return (
                <PreviewMobileWrapper preview="youtube">
                    <AffectivityPreview blockModel={blockModel} data={data} />
                </PreviewMobileWrapper>
            );
        }
        // Still asset
        else if (data.type === 'stillAsset') {
            return <StillAsset blockModel={blockModel} data={data} />;
        }
        // Display preview
        else if (data.type === 'displayPreview') {
            return <DisplayPreview blockModel={blockModel} data={data} language={this.props.editor.language} />;
        }
        // Email preview
        else if (data.type === 'emailPreview') {
            return <EmailPreview blockModel={blockModel} data={data} />;
        }
        // Email preview
        else if (data.type === 'landingpagePreview') {
            return <LandingpagePreview blockModel={blockModel} data={data} />;
        }
        // Data set manager
        else if (data.type === 'dataSetManager') {
            return <DataSetManagerInCampaign blockModel={blockModel} data={data} />;
        }
        // Campaign status
        else if (data.type === 'campaignStatus') {
            return <CampaignStatus blockModel={blockModel} data={data} />;
        }
        // Campaign flight manager
        else if (data.type === 'campaignFlightManager') {
            return <CampaignFlightManager blockModel={blockModel} data={data} />;
        }
        // Campaign flight calendar
        else if (data.type === 'campaignFlightCalendar') {
            return <CampaignFlightCalendar blockModel={blockModel} data={data} />;
        }
        // Creative buider preview
        else if (data.type === 'creativeBuilderPreview') {
            return <CreativeBuilderPreview blockModel={blockModel} data={data} />;
        }
        // Creative buider preview
        else if (data.type === 'socialCustomizer') {
            return <SocialCustomizer blockModel={blockModel} data={data} />;
        }

        // Customer specific blocks
        // #if process.env.customer === 'klm' || process.env.customer === 'afkl'  || process.env.customer === 'airfrance'
//         else if (data.type === 'emailAIGenerator') {
//             return <KLMEmailAIGenerator blockModel={blockModel} data={data} />;
//         }
        // #endif
        // #if process.env.customer === 'klm' || process.env.customer === 'afkl'  || process.env.customer === 'airfrance'
//         else if (data.type === 'afiframe') {
//             return <AFiFrame blockModel={blockModel} data={data} />;
//         }
        // #endif
        // #if process.env.customer === 'klm' || process.env.customer === 'afkl'  || process.env.customer === 'airfrance'
//         else if (data.type === 'klmFares') {
//             return <KLMFares blockModel={blockModel} />;
//         }
        // #endif
        // #if process.env.customer == 'philips'
//         else if (data.type === 'philipsTrafficking') {
//             return <PhilipsTrafficking blockModel={blockModel} data={data} />;
//         }
        // #endif
        else {
            return <MultiInput blockModel={blockModel} data={data} language={this.props.editor.language} />;
        }
    };

    /**
     * Render the block
     */
    render() {
        const { data, editor, compact = false } = this.props;
        const { opened, subsetSelected, commentReviewIsOpen } = this.state;
        // Parse subsets
        const subsets = data.subsets;
        if (subsets) {
            EditorData.parseDataDeep(subsets);
        }

        // Render compact block setup without header
        if (compact) {
            return (
                <div>
                    {subsets && (
                        <Subsets
                            blockModelBase={data.blockModelBase}
                            selected={subsetSelected}
                            canEdit={subsets.canEdit}
                            canChangeName={subsets.canChangeName}
                            onSetSubsetActive={this.handleChangeSubset}
                        />
                    )}

                    {this.renderBlockContent(data, data.blockModel, subsetSelected)}
                </div>
            );
        } else {
            let tourData;
            if (this.props.data.tourData) {
                tourData = this.props.data.tourData.selector;
            } else {
                tourData = 'none';
            }

            // Render block
            return (
                <div className="editor-block" data-tour-selector={tourData}>
                    {(!opened || data.title) && (
                        <div className="editor-block__title-bar" onClick={this.onToggle}>
                            {data.comments && (
                                <CommentReviewHeader
                                    title={data.comments.title ? data.comments.title : data.title}
                                    showStatus={data.comments.showStatus}
                                    campaignId={editor.id}
                                    entity={data.comments.entity}
                                    onOpen={() => this.commentReviewOpen(true)}
                                    onClose={() => this.commentReviewOpen(false)}
                                />
                            )}
                            {!data.comments && <div className="editor-block__title-bar__title">{data.title}</div>}

                            <div className="editor-block__title-bar__arrow">
                                {!opened && <Icon>expand_more</Icon>}
                                {opened && <Icon>expand_less</Icon>}
                            </div>
                        </div>
                    )}
                    {opened && (
                        <div className="editor-block__content">
                            {data.infoBox && <InlineAlert type="info" message={data.infoBox} />}

                            {commentReviewIsOpen && (
                                <div className="editor-block__content__review">
                                    <div className="editor-block__content__review__container">
                                        <Comments campaignId={editor.id} entity={data.comments.entity} canReview={data.comments.canReview} />
                                    </div>
                                </div>
                            )}

                            {subsets && (
                                <Subsets
                                    blockModelBase={data.blockModelBase}
                                    selected={subsetSelected}
                                    canEdit={subsets.canEdit}
                                    canChangeName={subsets.canChangeName}
                                    onSetSubsetActive={this.handleChangeSubset}
                                />
                            )}

                            {data.type === 'split' && (
                                <div className="editor-block__content__split">
                                    <div className="editor-block__content__main">
                                        {data.main && this.renderBlockContent(data.main, data.blockModel, subsetSelected)}
                                    </div>
                                    <div className="editor-block__content__side">
                                        {data.side && this.renderBlockContent(data.side, data.blockModel, subsetSelected)}
                                    </div>
                                </div>
                            )}
                            {data.type !== 'split' && this.renderBlockContent(data, data.blockModel, subsetSelected)}
                        </div>
                    )}
                </div>
            );
        }
    }
}

export default connect(EditorBlock.mapStateToProps, {})(EditorBlock);
