import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import Chip from 'components/ui-components-v2/Chip';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import EditorData from 'components/editor-data/EditorData';
import DataHelpers from 'components/data/DataHelpers';
import '../styles/main.scss';

/**
 * Editor sets
 * Allows you to create sets of the same content.
 * This component can be placed in several positions to create the subsets. The component handles the remove/add events and displays chips for the different options.
 * The component is for instance used in the CreativeEditor class.
 */
class EditorSubsets extends React.Component {
    static propTypes = {
        // To pass an array of the current subsets
        items: PropTypes.array,
        // To set a base block
        blockModelBase: PropTypes.string,
        // To use the onRemove handler
        onRemove: PropTypes.func,
        // To use the onAdd handler
        onAdd: PropTypes.func,
        // Set subset active
        onSetSubsetActive: PropTypes.func,
        // The selected subset ID
        selected: PropTypes.string,
        // Can Add
        canAdd: PropTypes.bool,
        // Can change names
        canChangeName: PropTypes.bool
    };

    static defaultProps = {
        canEdit: true
    };

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            mode: 'inObject',
            showRemoveConfirm: false,
            selectedSubset: props.selected
        };

        // The sets have a seperate prop, we use that prop. This is only for external mode.
        if (props.items) {
            this.state.mode = 'output';
            const label = props.items && props.items.length > 6 ? 'V' : 'Variant ';
            props.items.forEach((item, index) => {
                this.state.items.push({
                    key: item,
                    title: label + ' ' + (index + 1),
                    editingName: false
                });
            });
        }
        // There is no prop, we use the base model to find all sets
        else {
            const data = EditorData.getDynamicValue('[[[campaign]]].' + props.blockModelBase, {});
            const label = data && data.length > 6 ? 'V' : 'Variant ';

            if (data) {
                // Create the internal state of all objects.
                Object.keys(data).forEach((item, index) => {
                    this.state.items.push({
                        key: item,
                        title: data[item] && data[item]._subsetTitle ? data[item]._subsetTitle : label + (index + 1),
                        editingName: false
                    });
                });
            } else {
                this.state.items.push({
                    key: props.selected,
                    title: label + '1',
                    editingName: false
                });
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        const { items } = this.props;

        if (items && items !== prevProps.items) {
            const label = items && items.length > 6 ? 'V' : 'Variant ';
            const newItems = [];

            items.forEach((item, index) => {
                newItems.push({
                    key: item,
                    title: label + ' ' + (index + 1),
                    editingName: false
                });
            });

            this.setState({ items: newItems });
        }
    };

    /**
     * Handle delete confirm
     * Shows the dialog for deletion
     */
    handleRemoveConfirm = (removeItem) => {
        this.setState({
            showRemoveConfirm: true,
            removeItem: removeItem
        });
    };

    /**
     * Cancel removing from the dialog
     */
    handleRemoveCancel = () => {
        this.setState({
            showRemoveConfirm: false
        });
    };

    /**
     * Handle deletion of an item
     */
    handleRemove = () => {
        const { removeItem, selectedSubset } = this.state;
        const { blockModelBase, canChangeName } = this.props;

        const items = this.state.items.filter((item) => item.key !== removeItem);
        let selected = false;

        // Rename variants
        items.forEach((item, index) => {
            if (!canChangeName || !item.title) {
                item.title = 'Variant ' + (index + 1);
            }
            if (item.key === selectedSubset) {
                selected = true;
            }
        });

        let newSelectedSubset = selectedSubset;
        if (!selected) {
            newSelectedSubset = items[0].key;
            this.props.onSetSubsetActive(newSelectedSubset);
        }

        this.setState({
            items: items,
            selectedSubset: newSelectedSubset,
            showRemoveConfirm: false
        });

        // Set mode
        if (this.state.mode === 'inObject') {
            EditorData.removeItem(blockModelBase + '.' + removeItem);
        }
        // We have an externally managed subset, call prop
        else {
            const outputItems = items.map((item) => item.key);
            this.props.onRemove(removeItem, outputItems);
        }
    };

    /**
     * Add a new item
     */
    handleAddItem = () => {
        const { items } = this.state;
        const { blockModelBase, onAdd, onSetSubsetActive } = this.props;

        // Create new array
        const code = 's_' + uuidv4().replace(/-/g, '');
        const newTitle = 'Variant ' + (items.length + 1);

        const newItems = [...items, { key: code, title: newTitle }];

        // Copy previous option
        if (this.state.mode === 'inObject') {
            let copyKey = 'main';
            if (this.state.selectedSubset) {
                copyKey = this.state.selectedSubset;
            }
            let val = {};
            const newValue = EditorData.getValueFromModel(blockModelBase + '.' + copyKey);

            if (newValue) {
                val = DataHelpers.clone(newValue, false);
            }

            EditorData.setModel(blockModelBase + '.' + code, val);
            EditorData.setModel(blockModelBase + '.' + code + '._subsetTitle', newTitle);
            onSetSubsetActive(code);
        }
        // Not in object, call event
        else {
            const outputItems = newItems.map((item) => item.key);
            onAdd(code, outputItems);
        }

        this.setState({
            items: newItems,
            selectedSubset: code
        });
    };

    /**
     * Set subset to active
     */
    onSetSubsetActive = (key) => {
        const { selectedSubset, items } = this.state;
        const { canChangeName } = this.props;
        this.props.onSetSubsetActive(key);

        // Start editing the name
        items.forEach((item) => {
            item.editingName = false;
            if (selectedSubset === key && canChangeName) {
                if (item.key === selectedSubset) {
                    item.editingName = true;
                }
            }
        });

        this.setState({
            selectedSubset: key,
            items: items
        });
    };

    /**
     * Change name of the tab
     */
    handleChangeName = (itemToChange, e) => {
        const { blockModelBase } = this.props;
        // Change internal state
        const { items } = this.state;
        items.forEach((item) => {
            if (item.key === itemToChange.key) {
                item.title = e.target.value;
            }
        });
        const newName = e.target.value;
        this.setState({
            items: items
        });

        // Wait and change in final model
        clearTimeout(this.nameChangeTimout);
        this.nameChangeTimout = setTimeout(() => {
            EditorData.setModel(blockModelBase + '.' + itemToChange.key + '._subsetTitle', newName);
        }, 300);
    };

    /**
     * Blur the element
     * Remove the textbox for editing
     * @param {*} itemToChange
     * @param {*} e
     */
    handleNameBlur = (itemToChange) => {
        const { items } = this.state;
        items.forEach((item) => {
            if (item.key === itemToChange.key) {
                item.editingName = false;
            }
        });
        this.setState({
            items: items
        });
    };

    render() {
        const { canEdit, canChangeName } = this.props;
        const { selectedSubset, items, showRemoveConfirm } = this.state;

        // Render page
        return (
            <div className="editor-subsets">
                {showRemoveConfirm && (
                    <ConfirmDialog
                        open={true}
                        title="Delete block?"
                        description="Are you sure you want to delete this block (cannot be  undone)"
                        onConfirm={this.handleRemove}
                        onClose={this.handleRemoveCancel}
                    />
                )}

                {items.map((item) => (
                    <React.Fragment key={'subset__' + item.key}>
                        {canChangeName && item.editingName && (
                            <Chip
                                key={item.key}
                                label={
                                    <input
                                        onBlur={(e) => this.handleNameBlur(item, e)}
                                        onChange={(e) => this.handleChangeName(item, e)}
                                        className="editor-subsets__chip-input"
                                        type="text"
                                        value={item.title}
                                    />
                                }
                                color={selectedSubset === item.key ? 'primary' : undefined}
                                onClick={() => this.onSetSubsetActive(item.key)}
                                style={{ marginRight: 5 }}
                            />
                        )}
                        {items.length > 1 && canEdit && !item.editingName && (
                            <Chip
                                key={item.key}
                                label={item.title}
                                color={selectedSubset === item.key ? 'primary' : undefined}
                                onDelete={() => this.handleRemoveConfirm(item.key)}
                                onClick={() => this.onSetSubsetActive(item.key)}
                                style={{ marginRight: 5 }}
                            />
                        )}
                        {(items.length === 1 || !canEdit) && !item.editingName && (
                            <Chip
                                key={item.key}
                                color={selectedSubset === item.key ? 'primary' : undefined}
                                label={item.title}
                                onClick={() => this.onSetSubsetActive(item.key)}
                                style={{ marginRight: 5 }}
                            />
                        )}
                    </React.Fragment>
                ))}

                {canEdit && <Chip icon={<AddIcon />} label="Add variant" onClick={() => this.handleAddItem()} />}
            </div>
        );
    }
}

export default EditorSubsets;
